<template>
  <div>
    <ManageTicket />
  </div>
</template>
<script>
import ManageTicket from "@/components/Ticket/ManageTicket";
export default {
  components: {
    ManageTicket,
  },
  created() {},
};
</script>
c
